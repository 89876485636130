import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            As a <span className="purple">Software Development Engineer </span>
            at UPS, I design and develop scalable and reliable cloud applications using Java, 
            OpenShift, and Spring Boot. I have a Master of Computer Science from University of 
            Louisville and a Bachelor of Science in Computer Science from Indiana University 
            Southeast. I have contributed to multiple projects that improved the efficiency, 
            security, and performance of UPS's logistics and delivery systems. I am passionate 
            about solving complex problems, learning new technologies, and collaborating with 
            diverse teams. I value innovation, quality, and customer satisfaction.
            <br />
            <br />
            Apart from Software Development Engineering, here are some other activities that I enjoy doing!
          </p>
          <ul>
            <li className="about-activity">
              • Mountain Biking
              <br />• Skiing
              <br />• Traveling
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
